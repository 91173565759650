:root {
  --tab-list-height: 3rem;
  --collapsed-tab-list-height: 3rem;
}

.panelSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-block-end: 1px solid var(--color-border);
  padding: var(--spacing-24);
  gap: var(--spacing-16);
  position: relative;
  height: max-content;
}

.content {
  padding: 0;
  height: 100%;
}

.scoreAccuracy {
  display: block;
  padding-block-start: var(--spacing-16);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
}

.scoreAccuracyExplanation > p {
  margin-block-end: var(--spacing-08);
}

.header {
  margin: var(--spacing-08) 12px;
}

.header > *:not(:last-child) {
  margin-block-end: var(--spacing-04) !important;
}

.employeeListContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.fullHeightTabPanel {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}
